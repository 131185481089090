/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Tabs from "@civicplus/preamble-ui/lib/Tabs";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Link from "@civicplus/preamble-ui/lib/Link";
import { MessageHistory } from "../../../../entities/Message";
import { SubscriptionList } from "../../../../entities/SubscriptionList";
import { bottle } from "../../../../provider/Bottle";
import { MessageService } from "../../../../services/MessageService";
import MessageHistoryEmailActivity from "./MessageHistoryEmailActivity";
import MessageHistoryEmailDetailsTab from "./MessageHistoryEmailDetailsTab";
import MessageHistorySmsActivityTab from "./MessageHistorySmsActivityTab";
import MessageHistorySmsDetailsTab from "./MessageHistorySmsDetailsTab";
import MessageHistoryStatisticsTab from "./MessageHistoryStatisticsTab";

interface MessageHistoryTabsProps {
    selectedTab: number;
    messageHistory: MessageHistory | null;
    orgId: any;
    messageId: number;
    list: SubscriptionList | null;
    isPendingMessage?: boolean;
}

const MessageHistoryTabs: React.FC<MessageHistoryTabsProps> = (props: MessageHistoryTabsProps) => {
    const messageService: MessageService = bottle.container.MessageService;
    const { messageHistory, orgId, list, messageId, isPendingMessage } = props;
    const [selectedTab, setSelectedTab] = useState<number>(props.selectedTab);
    const [emailCount, setEmailCount] = useState<number>(0);
    const [smsCount, setSmsCount] = useState<number>(0);
    const [stats, setStats] = useState<any[]>([]);

    useEffect(() => {
        let isCancelled = false;
        async function getStatsResults() {
            const results = await Promise.all([
                await messageService.getSentMessageCounts(messageId, list?.id),
                await messageService.getSentMessageChartStats(messageId, list?.id)
            ]);

            const chart: any[] = [];

            for (const [date, stats] of Object.entries(results[1]!)) {
                chart.push({
                    date: date,
                    opened: stats.find((m: any) => m.type === "email_opened")?.count ?? 0,
                    bounced: stats.find((m: any) => m.type === "email_bounced")?.count ?? 0
                });
            }

            if (!isCancelled) {
                setEmailCount(results[0].email);
                setSmsCount(results[0].sms);
                setStats(chart);
            }
        }

        getStatsResults();
        return () => {
            isCancelled = true;
        };
    }, [list, messageId, messageService]);

    const getBreadcrumbs = () => {
        if (list) {
            return [
                <Link key="list" component={RouterLink} to={`/${orgId}/admin/lists`}>
                    Subscription Lists
                </Link>,

                <Link component={RouterLink} key="current-list" to={`/${orgId}/admin/lists/${list?.id}/edit`}>
                    {list?.name}
                </Link>,

                <Link
                    key="history"
                    component={RouterLink}
                    to={`/${orgId}/admin/lists/${list?.id}/messages/${isPendingMessage ? "pending" : "history"}`}
                >
                    {isPendingMessage ? "Pending Messages" : "Message History"}
                </Link>
            ];
        }

        return [
            <Link
                key="history"
                component={RouterLink}
                to={`/${orgId}/admin/messages/${isPendingMessage ? "pending" : "history"}`}
            >
                {isPendingMessage ? "Pending Messages" : "Message History"}
            </Link>
        ];
    };

    if (messageHistory === null) {
        return <></>;
    }

    return (
        <Grid container={true} spacing={3}>
            <Grid xs={12} id="titlebar-grid">
                <Titlebar
                    id="titlebar"
                    title={messageHistory?.subject ? messageHistory.subject : ""}
                    breadcrumbs={getBreadcrumbs()}
                />
            </Grid>

            <Grid xs={12}>
                <Tabs
                    value={selectedTab}
                    onChange={(event, value) => {
                        setSelectedTab(value);
                    }}
                    id="message-tabs"
                    tabContents={[
                        {
                            label: "Statistics",
                            contents: (
                                <MessageHistoryStatisticsTab
                                    emailCount={emailCount}
                                    smsCount={smsCount}
                                    stats={stats}
                                />
                            )
                        },
                        {
                            label: "Email Activity",
                            contents: (
                                <MessageHistoryEmailActivity
                                    listId={props.list?.id ?? null}
                                    messageId={props.messageId}
                                    messageHistory={messageHistory}
                                />
                            ),
                            disabled: isPendingMessage
                        },
                        {
                            label: "Email Details",
                            contents: <MessageHistoryEmailDetailsTab messageHistory={messageHistory} orgId={orgId} />
                        },
                        {
                            label: "Sms Activity",
                            contents: (
                                <MessageHistorySmsActivityTab
                                    listId={props.list?.id ?? null}
                                    messageId={props.messageId}
                                    messageHistory={messageHistory}
                                />
                            ),
                            disabled: isPendingMessage
                        },
                        {
                            label: "Sms Details",
                            contents: <MessageHistorySmsDetailsTab messageHistory={messageHistory} orgId={orgId} />
                        }
                    ]}
                    color="default"
                    variant="fullWidth"
                    position="static"
                />
            </Grid>
        </Grid>
    );
};

export default MessageHistoryTabs;
