import React, { useState, useCallback, useEffect, useRef } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import DateTimePicker from "@civicplus/preamble-ui/lib/DateTimePicker";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import { bottle } from "../../../provider/Bottle";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { OrganizationSettings } from "../../../entities/OrganizationSettings";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { OrganizationService } from "../../../services/OrganizationService";
import { softMaxLengthValidation } from "../shared/customValidations";
import { stringHasValue } from "../shared/functions";
import { usePrompt } from "../../../util/DirtyPrompt";
import { UserManager } from "../../../services/UserManager";
import { useSnackbar } from "notistack";
import enhanceWithValidation, {
    requiredValidation,
    ValidationResult,
    emailValidation,
    specialCharactersValidation
} from "@civicplus/preamble-ui/lib/Validations";

const EnhancedTextInput = enhanceWithValidation(TextInput);
const EnhancedDateTimePicker = enhanceWithValidation(DateTimePicker);

const useStyles = makeStyles(() => ({
    enableSmsText: {
        fontSize: "0.75rem",
        color: "rgba(0,0,0,.6)",
        margin: "3px 0 0"
    },
    gridItem: {
        margin: "15px 0 0"
    }
}));

const OrganizationSettingsView: React.FC = () => {
    const organizationSettingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;
    const orgService: OrganizationService = bottle.container.OrganizationService;
    const userManager = bottle.container.UserManager as UserManager;

    const [form, setForm] = useState<OrganizationSettings>({
        id: "",
        defaultListSendTime: new Date(),
        defaultListSendTimeZone: "",
        defaultListSendTimeZoneLabel: "",
        defaultListSenderName: "",
        defaultListReplyToEmailAddress: "",
        isSMSEnabled: false
    });

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
    const [toggleIsSMSEnabled, setToggleIsSMSEnabled] = useState<boolean>(false);
    const [error, setError] = useState<string | null>("");

    const emailRef = useRef<{ validate: () => ValidationResult }>();
    const senderNameRef = useRef<{ validate: () => ValidationResult }>();

    const load = useCallback(async () => {
        const settings = await organizationSettingsService.getOrganizationSettings();
        const isSuperUser = await userManager.isSuperUser();

        if (settings === null) {
            setError("Organization was not found");

            return;
        }

        setForm(settings);
        setIsSuperUser(isSuperUser);
        setToggleIsSMSEnabled(settings.isSMSEnabled);
        setIsLoading(false);
    }, [organizationSettingsService, userManager]);

    useEffect(() => {
        async function init() {
            await load();
        }
        init();
    }, [load]);

    const onSave = async () => {
        try {
            if (!stringHasValue(form.defaultListReplyToEmailAddress) || !stringHasValue(form.defaultListSenderName)) {
                enqueueSnackbar("All fields must be valid to continue.", { variant: "error" });
                return;
            }

            setIsSaving(true);

            await organizationSettingsService.updateOrganizationSettings(form);

            setIsSaving(false);
            setIsDirty(false);

            enqueueSnackbar("Changes saved successfully!", { variant: "success" });
        } catch (ex: any) {
            setIsSaving(false);
            setError(ex.error);
            return { error: ex.error, skipToastNotification: true };
        }
    };

    usePrompt("Are you sure you want to discard changes?", isDirty);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error", onClose: () => setError("") });
        }
    }, [enqueueSnackbar, error]);

    return (
        <>
            <Titlebar
                id="titlebar"
                title="Defaults"
                breadcrumbs={[
                    <Link
                        key="admin"
                        id="admin"
                        //@ts-ignore
                        to={`/${orgService.apiService._orgId}/admin`}
                        component={RouterLink}
                    >
                        Administration
                    </Link>
                ]}
                buttons={[
                    <Button
                        id="save-settings-btn"
                        key="save-settings-btn"
                        color="primary"
                        isLoading={isSaving}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        Save
                    </Button>
                ]}
            />

            {isLoading ? (
                <Loader verticallyCenter={true} />
            ) : (
                <Grid container={true}>
                    {isSuperUser ? (
                        <Grid style={{ marginTop: 15 }} xs={12} md="auto">
                            <Toggle
                                label="Enabled SMS"
                                checked={toggleIsSMSEnabled}
                                onChange={() => {
                                    setToggleIsSMSEnabled(!toggleIsSMSEnabled);
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        isSMSEnabled: !toggleIsSMSEnabled
                                    }));
                                    setIsDirty(true);
                                }}
                            />

                            <p className={classes.enableSmsText}>
                                Enable to turn on SMS messages for this organization
                            </p>
                        </Grid>
                    ) : null}

                    <Grid xs={12} className={classes.gridItem}>
                        <EnhancedTextInput
                            id="sender-name-input"
                            label="Sender Name"
                            required={true}
                            ref={senderNameRef}
                            fullWidth={true}
                            disabled={isSaving}
                            softMaxLength={50}
                            value={form.defaultListSenderName}
                            validations={[requiredValidation, softMaxLengthValidation, specialCharactersValidation]}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        defaultListSenderName: e.currentTarget?.value
                                    }));
                                    setIsDirty(true);
                                }
                            }}
                        />
                    </Grid>

                    <Grid xs={12} className={classes.gridItem}>
                        <EnhancedTextInput
                            id="reply-email-input"
                            label="Reply-To Email Address"
                            required={true}
                            fullWidth={true}
                            ref={emailRef}
                            disabled={isSaving}
                            value={form.defaultListReplyToEmailAddress}
                            validations={[requiredValidation, emailValidation]}
                            helperText="This email address will be used when the user chooses to reply to an email notification."
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e && e.currentTarget) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        defaultListReplyToEmailAddress: e.currentTarget?.value
                                    }));
                                    setIsDirty(true);
                                }
                            }}
                        />
                    </Grid>

                    <Grid xs={12} md="auto" className={classes.gridItem}>
                        <EnhancedDateTimePicker
                            id="send-time-input"
                            label="Send Time"
                            required={true}
                            disabled={isSaving}
                            pickerType="time"
                            value={form.defaultListSendTime}
                            validations={[requiredValidation]}
                            helperText="This is the time notifications will be sent for items with a scheduled publish date."
                            onChange={(e: Date) => {
                                if (e) {
                                    setForm((prevForm) => ({
                                        ...prevForm,
                                        defaultListSendTime: e
                                    }));
                                    setIsDirty(true);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default OrganizationSettingsView;
