import { CppOrganization } from "@civicplus/preamble-ui/lib/Services/OrgService/Types/Organization";
import { Organization, OrgApplicationDto } from "../../../entities/Organization";

export function getCppOrganizationFromOrganization(organization: Organization): CppOrganization {
    return {
        ...omit<Organization>(organization, ["applications"]),
        applications: organization.applications?.map((x) => {
            return {
                ...omit<OrgApplicationDto>(x, ["productType"]),
                productType: x.productType
            };
        })
    } as CppOrganization;
}

export function omit<T extends object>(obj: T, paths: Array<keyof T>): Omit<T, keyof T> {
    const ret = Object.create(null);
    Object.entries(obj).forEach(([key, value], index) => {
        if (!paths.includes(key as keyof T)) {
            ret[key] = value;
        }
    });
    return ret;
}

export const stringHasValue = (string: string | undefined): boolean => {
    if (string === undefined || string === "" || string === null) {
        return false;
    }

    return true;
};
