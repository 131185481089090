import React, { useState, useEffect } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import TextInput from "@civicplus/preamble-ui/lib/TextInput";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { bottle } from "../../../provider/Bottle";
import { PrivacyService } from "../../../services/PrivacyService";
import { User } from "../../../entities/Subscriber";

export const Privacy: React.FC = () => {
    const [userEmail, setUserEmail] = useState("");
    const [userInfo, setUserInfo] = useState<User>();
    const [deleteUserResult, setDeleteUserResult] = useState("");
    const [resultMessage, setResultMessage] = useState("");

    const privacyService: PrivacyService = bottle.container.PrivacyService;

    const handleChange = (event: any) => {
        setUserEmail(event.target.value);
    };

    const handleSearch = async () => {
        setUserInfo(await privacyService.getUserInfoInOrganization(userEmail));
    };

    const handleDeleteUser = async () => {
        if (userInfo) {
            setDeleteUserResult(await privacyService.deleteUser(userInfo.id));
        }
    };

    useEffect(() => {
        if (deleteUserResult) {
            setResultMessage(deleteUserResult);
            setUserEmail("");
        } else {
            setResultMessage(userInfo ? "" : "No user found.");
        }
    }, [userInfo, deleteUserResult]);

    useEffect(() => {
        setResultMessage("");
    }, [privacyService]);

    return (
        <Grid container={true} spacing={10}>
            {!deleteUserResult && (
                <Grid xs={12}>
                    <TextInput value={userEmail} label="User Email" onChange={handleChange} />
                    <ButtonGroup layout="left">
                        <Button color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                    </ButtonGroup>
                </Grid>
            )}

            {userInfo && !resultMessage && (
                <Grid xs={12}>
                    <Typography variant="h5">User Info</Typography>
                    <Typography variant="body1">Id: {userInfo.id}</Typography>
                    <Typography variant="body1">First Name: {userInfo.firstName}</Typography>
                    <Typography variant="body1">Last Name: {userInfo.lastName}</Typography>
                    <Typography variant="body1">Email: {userInfo.email}</Typography>
                    <Typography variant="body1">Phone Number: {userInfo.phoneNumber}</Typography>
                    <ButtonGroup layout="left">
                        <Button color="secondary" onClick={handleDeleteUser}>
                            Delete User From Organization
                        </Button>
                    </ButtonGroup>
                </Grid>
            )}

            {resultMessage && (
                <Grid xs={12}>
                    <Typography variant="h5">{resultMessage}</Typography>
                </Grid>
            )}
        </Grid>
    );
};
