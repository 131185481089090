import React, { useCallback, useEffect, useState } from "react";
import Button from "@civicplus/preamble-ui/lib/Button";
import ButtonGroup from "@civicplus/preamble-ui/lib/ButtonGroup";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Link from "@civicplus/preamble-ui/lib/Link";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import SearchInput from "@civicplus/preamble-ui/lib/SearchInput";
import Table from "@civicplus/preamble-ui/lib/Table";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { bottle } from "../../../provider/Bottle";
import { format } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { OrganizationService } from "../../../services/OrganizationService";
import { SourceLabels, Suppression } from "../../../entities/Suppression";
import { SuppressionsService } from "../../../services/SuppressionsService";
import { MessageService } from "../../../services/MessageService";
import { EmailEvent } from "../../../entities/Message";
import { useSnackbar } from "notistack";

export const UserActivity: React.FC = () => {
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [suppressions, setSuppressions] = useState<Suppression[]>([]);
    const [emailEvents, setEmailEvents] = useState<EmailEvent[]>([]);
    const [emailCount, setEmailCount] = useState<number>(0);
    const [scrollToTop, setScrollToTop] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const orgService: OrganizationService = bottle.container.OrganizationService;
    const suppressionsService: SuppressionsService = bottle.container.SuppressionsService;
    const messageService: MessageService = bottle.container.MessageService;
    const { enqueueSnackbar } = useSnackbar();

    const handleDeleteSuppressions = async () => {
        if (userEmail) {
            try {
                await suppressionsService.deleteSuppressions(userEmail);
                enqueueSnackbar("Suppression successfully removed.", { variant: "success" });
                const suppressions = await suppressionsService.getSuppressions(userEmail);
                setSuppressions(suppressions);
            } catch (e) {
                enqueueSnackbar("Failed to remove suppressions. ", { variant: "error" });
            }
        }
    };

    const changePage = (page: number) => {
        setPage(page);
    };

    const changeRowsPerPage = (rows: number) => {
        setRowsPerPage(rows);
        setPage(0);
    };

    useEffect(() => {
        if (!loading && scrollToTop) {
            window.scroll(0, 0);
        }
    }, [rowsPerPage, loading, scrollToTop, emailEvents]);

    const getEmailEvents = useCallback(
        async (email: string | null) => {
            if (email) {
                const emailEvents = await messageService.getMessageEventsForEmail(
                    email,
                    rowsPerPage,
                    page * rowsPerPage
                );
                setEmailEvents(emailEvents.items);
                setEmailCount(emailEvents.count);
            }
        },
        [messageService, page, rowsPerPage]
    );

    const findByEmail = useCallback(
        async (email: string, scrollToTop = false) => {
            try {
                setSuppressions([]);
                setEmailEvents([]);
                setEmailCount(0);

                setUserEmail(email);
                setLoading(true);
                const suppressions = await suppressionsService.getSuppressions(email);
                setSuppressions(suppressions);

                await getEmailEvents(email);
                setScrollToTop(scrollToTop);
            } finally {
                setLoading(false);
            }
        },
        [suppressionsService, getEmailEvents]
    );

    useEffect(() => {
        getEmailEvents(userEmail);
        setScrollToTop(true);
    }, [getEmailEvents, page, userEmail]);

    return (
        <>
            <Titlebar
                id="titlebar"
                title="User Activity"
                description="Search for a user's email to find email events and suppressions."
                breadcrumbs={[
                    <Link
                        key="admin"
                        id="admin"
                        //@ts-ignore
                        to={`/${orgService.apiService._orgId}/admin`}
                        component={RouterLink}
                    >
                        Administration
                    </Link>
                ]}
            />

            <SearchInput
                id="user-lookup"
                placeholder="Enter email address..."
                onSearch={findByEmail}
                searchOnChange={false}
                fullWidth={true}
                margin={false}
                style={{ marginTop: 20 }}
            />

            {userEmail !== null && loading === true && <Loader />}

            {!loading && userEmail !== null && (
                <Grid container={true}>
                    <Grid xs={12}>
                        <div style={{ marginTop: 25 }}>
                            <Typography variant="h5" title="Email Events" style={{ marginBottom: 15 }}>
                                Email Events
                            </Typography>

                            <Table
                                id="events-table"
                                emptyState="No events found"
                                pagination={{
                                    count: emailCount,
                                    page: page,
                                    rowsPerPage: rowsPerPage,
                                    rowsPerPageOptions: [10, 25, 50],
                                    onPageChange: (event, page) => changePage(page),
                                    onRowsPerPageChange: (event) => changeRowsPerPage(Number(event.target.value))
                                }}
                                columns={[
                                    {
                                        columnHeader: "Id",
                                        rowProperty: "id"
                                    },
                                    {
                                        columnHeader: "Message Id",
                                        rowProperty: "messageId"
                                    },
                                    {
                                        columnHeader: "Subject",
                                        rowProperty: "subject"
                                    },
                                    {
                                        columnHeader: "Type",
                                        rowProperty: "type"
                                    },
                                    {
                                        columnHeader: "Tag",
                                        rowProperty: "tag"
                                    },
                                    {
                                        columnHeader: "Time",
                                        rowProperty: "time"
                                    }
                                ]}
                                rows={emailEvents.map((item) => {
                                    return {
                                        ...item,
                                        time: format(new Date(`${item.time.toString()}Z`), "MM/dd/yyyy hh:mm aaaa")
                                    };
                                })}
                            />
                        </div>
                    </Grid>

                    <Grid xs={12}>
                        <Typography variant="h5" title="Email Suppressions">
                            Email Suppressions
                        </Typography>

                        <Table
                            id="suppression-table"
                            emptyState="No suppressions found"
                            columns={[
                                {
                                    columnHeader: "Email",
                                    rowProperty: "to"
                                },
                                {
                                    columnHeader: "Source",
                                    rowProperty: "source"
                                },
                                {
                                    columnHeader: "Description",
                                    rowProperty: "description"
                                },
                                {
                                    columnHeader: "Code",
                                    rowProperty: "code"
                                },
                                {
                                    columnHeader: "Time",
                                    rowProperty: "time"
                                }
                            ]}
                            rows={suppressions?.map((item) => {
                                return {
                                    to: item.to,
                                    description: item.description,
                                    source: SourceLabels[item.source],
                                    code: item.errorCode,
                                    time: format(
                                        new Date(item.time.endsWith("Z") ? item.time : `${item.time}Z`),
                                        "MM/dd/yyyy hh:mm aaaa"
                                    )
                                };
                            })}
                        />
                    </Grid>

                    <Grid xs={3}>
                        {(suppressions?.length ?? 0) > 0 && (
                            <ButtonGroup layout="left">
                                <Button color="primary" onClick={handleDeleteSuppressions} style={{ marginTop: 15 }}>
                                    Delete Suppressions
                                </Button>
                            </ButtonGroup>
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    );
};
