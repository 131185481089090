/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import AdvancedTableWrapper from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Table from "@civicplus/preamble-ui/lib/Table";
import TableBody from "@civicplus/preamble-ui/lib/TableBody";
import TableCell from "@civicplus/preamble-ui/lib/TableCell";
import TableRow from "@civicplus/preamble-ui/lib/TableRow";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { bottle } from "../../../../provider/Bottle";
import { fromField } from "./shared";
import { format } from "date-fns";
import { getUsersName } from "@civicplus/preamble-ui/lib/Utilities/StringHelper";
import { MessageHistory } from "../../../../entities/Message";
import { MessageService } from "../../../../services/MessageService";
import { parseISO } from "date-fns/fp";
import {
    TableColumn,
    TableData,
    TableState
} from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";

interface IMessageHistoryEmailActivityProps {
    listId: number | null;
    messageId: number;
    messageHistory: MessageHistory;
}

const MessageHistoryEmailActivity: React.FC<IMessageHistoryEmailActivityProps> = ({
    listId,
    messageId,
    messageHistory
}) => {
    const messageService: MessageService = bottle.container.MessageService;
    const [messageDetails, setMessageDetails] = useState<any>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const showEmailActivityDialog = async (id: string) => {
        const details = await messageService.getMessageActivityDetails(messageId, id);

        setMessageDetails(details);
        setDialogOpen(true);
    };

    const getEmailActivityDetails = () => {
        let to = "";
        let from = "";

        const rows = messageDetails?.map((item: any) => {
            if (item.to) {
                to = item.to;
            }

            if (item.metaData && item.metaData.from) {
                const replyTo = item.metaData.replyTo ? item.metaData.replyTo : "noreply@civicplus.com";
                from = `${item.metaData.from} <${replyTo}>`;
            }

            return (
                <TableRow id={`${item.from}-${item.time}`} key={`${item.from}-${item.time}`}>
                    <TableCell scope="row" id={`${item.from}-${item.time}-date`}>
                        {format(
                            new Date(parseISO(item.time.endsWith("Z") ? item.time : `${item.time}Z`)),
                            "MM/dd/yyyy h:mm aaaa"
                        )}
                    </TableCell>

                    <TableCell id={`${item.from}-${item.time}-event`}>
                        <Typography variant="button">{item.type.replace("email_", "")}</Typography>
                    </TableCell>
                </TableRow>
            );
        });

        // Show Some info first. Like Email address and message info?
        return (
            <>
                <Grid xs={12}>
                    <Grid container={true}>
                        <Grid xs={12} sm={3} md={2}>
                            <Typography paragraph={true}>Sender Name:</Typography>
                        </Grid>

                        <Grid xs={12} sm={9} md={10}>
                            <Typography color="textSecondary">{getUsersName(messageHistory!.sender!)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12}>
                    <Grid container={true}>
                        <Grid xs={12} sm={3} md={2}>
                            <Typography paragraph={true}>Subject:</Typography>
                        </Grid>

                        <Grid xs={12} sm={9} md={10}>
                            <Typography color="textSecondary" data-testid="subjectValue">
                                {messageHistory!.subject!}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {fromField(messageHistory!, from)}

                <Grid xs={12}>
                    <Grid container={true}>
                        <Grid xs={12} sm={3} md={2}>
                            <Typography paragraph={true}>To:</Typography>
                        </Grid>

                        <Grid xs={12} sm={9} md={10}>
                            <Typography color="textSecondary">{to}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12}>
                    <Grid container={true}>
                        <Grid xs={12}>
                            <Table compact={true} id="activity-details">
                                <TableBody id="activity-details-body">{rows}</TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    const getEmailActivityRows = async (state: TableState): Promise<TableData> => {
        const activity = await messageService.getSentMessageActivity(messageId, listId, state.page, state.rowsPerPage);

        const items = activity.items.map((item: any) => {
            return [
                <Typography variant="button">{item.type.replace("email_", "")}</Typography>,
                item.to ?? "",
                format(
                    new Date(parseISO(item.time.endsWith("Z") ? item.time : `${item.time}Z`)),
                    "MM/dd/yyyy h:mm aaaa"
                ),
                item.messageId
            ];
        });

        return { count: activity.count, data: items };
    };

    const columns: TableColumn[] = [
        { name: "event", label: "Event" },
        {
            name: "recipient",
            options: {
                label: "Recipient",
                filter: false
            }
        },
        {
            name: "date",
            label: "Date",
            options: {
                sort: true,
                filter: false
            }
        },
        {
            name: "messageId",
            label: "Message Id",
            options: { sort: false, filter: false, display: "false" }
        }
    ];

    return (
        <>
            <Dialog
                title="Message Log Details"
                fullWidth={true}
                maxWidth="xl"
                open={dialogOpen}
                data-testid="email-activity-dialog"
                onClose={() => {
                    setDialogOpen(false);
                    setMessageDetails(null);
                }}
                actions={[
                    <Button
                        color="primary"
                        onClick={async () => {
                            setDialogOpen(false);
                            setMessageDetails(null);
                        }}
                        key="ok"
                    >
                        OK
                    </Button>
                ]}
            >
                {messageDetails && getEmailActivityDetails()}
            </Dialog>

            <AdvancedTableWrapper
                columns={columns}
                rows={getEmailActivityRows}
                scrollToTop={true}
                emptyMessage="There is currently no events to view."
                download={false}
                initialSortDirection="desc"
                initialSortColumn="date"
                initialSortColumnIndex={2}
                showFilter={false}
                rowsPerPage={25}
                rowsPerPageOptions={[25, 50, 100]}
                onRowClick={async (rowData: string[], rowMeta: unknown) => {
                    const id = rowData[3];
                    await showEmailActivityDialog(id);
                }}
            />
        </>
    );
};
export default MessageHistoryEmailActivity;
