import React, { useEffect, useState } from "react";
import AdvancedTableWrapper from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Link from "@civicplus/preamble-ui/lib/Link";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import { bottle } from "../../../provider/Bottle";
import { buildCustomSelectPickerFilter, buildDateFilter } from "@civicplus/preamble-ui/lib/Utilities/odataHelper";
import { buildUserFilterFromOptionShape } from "../../../util/FilterHelper";
import { debounce } from "../../../util/Debounce";
import { HistoryLogService } from "../../../services/HistoryLogService";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { searchUsers } from "../../../util/UserSearch";
import { TableData, TableState } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { Link as RouterLink } from "react-router-dom";
import { OrganizationService } from "../../../services/OrganizationService";
import { usePreviousErrors } from "../../../util/PreviousErrors";
import { useSnackbar } from "notistack";
import {
    makeCustomSelectPicker,
    makeDateRangeFilter,
    makeUsersFilter,
    LastModified
} from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";

const HistoryLog: React.FC = () => {
    const [error, setError] = useState<string>("");
    const prevError = usePreviousErrors(error);
    const { enqueueSnackbar } = useSnackbar();

    const orgService: OrganizationService = bottle.container.OrganizationService;
    const historyLogService: HistoryLogService = bottle.container.HistoryLogService;
    const settingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;
    const actions: string[] = ["Created", "Updated", "Deleted", "Modified", "Canceled", "Gave", "Removed"];
    const entityTypes: { label: string; value: string | string[] }[] = [
        { label: "Subscription List", value: "SubscriptionList" },
        { label: "Template", value: "Templates" },
        { label: "Permissions", value: "Permissions" },
        { label: "Organization Settings", value: "Settings" },
        { label: "Message", value: ["HCMSAsset", "HCMSContent"] }
    ];

    const getRows = async (state: TableState): Promise<TableData> => {
        const historyItems = await historyLogService.getHistory(
            state.page,
            state.rowsPerPage,
            state.sortOrder.name || "actionDate",
            state.sortOrder.direction || "desc",
            {
                ...buildDateFilter(state.filterList[1], "actionDate"),
                ...buildCustomSelectPickerFilter(state.filterList[2], "action"),
                ...buildCustomSelectPickerFilter(state.filterList[3], "entityType"),
                ...buildUserFilterFromOptionShape(state.filterList[4], "actionUserId")
            }
        );
        const data = historyItems.items.map((item) => {
            return [
                <div key="html-summary" dangerouslySetInnerHTML={{ __html: item.summary }} />,
                <LastModified
                    key="last-modified-history-item"
                    date={item.actionDate}
                    organizationTimeZone={settingsService.settings.defaultListSendTimeZoneLabel}
                />
            ];
        });
        return { count: historyItems.count, data };
    };

    const buildBody = () => {
        const timeZone = settingsService.settings.defaultListSendTimeZoneLabel;
        const columns = [
            { name: "summary", label: "Summary" },
            {
                name: "actionDate",
                label: "Action Date",
                options: {
                    ...makeDateRangeFilter("Action Date", timeZone, true),
                    sort: true,
                    filter: true
                }
            },
            {
                name: "action",
                options: {
                    ...makeCustomSelectPicker(
                        "Action",
                        actions.map((v) => ({
                            label: v === "Removed" || v === "Gave" ? `${v} Permission` : v,
                            value: v.toLowerCase()
                        }))
                    ),
                    viewColumns: false,
                    display: "exclude",
                    filter: true
                }
            },
            {
                name: "entityType",
                options: {
                    ...makeCustomSelectPicker("EntityType", entityTypes),
                    viewColumns: false,
                    display: "exclude",
                    filter: true
                }
            },
            {
                name: "actionUser",
                options: {
                    ...makeUsersFilter("User", debounce(searchUsers, 300), null, true),
                    viewColumns: false,
                    display: "exclude",
                    filter: true
                }
            }
        ];

        return (
            <AdvancedTableWrapper
                columns={columns}
                rows={getRows}
                scrollToTop={true}
                emptyMessage="There is currently no history to view."
                onDownload={getExportFile}
                download={true}
                initialSortDirection="desc"
                initialSortColumn="actionDate"
                initialSortColumnIndex={1}
                showFilter={true}
                rowsPerPage={25}
                rowsPerPageOptions={[25, 50, 100]}
            />
        );
    };

    const getExportFile = async () => {
        try {
            await historyLogService.getExport();
        } catch (e: any) {
            setError(e.message);
        }
    };

    useEffect(() => {
        if (error && error !== prevError) {
            enqueueSnackbar(error, { variant: "error" });
        }
    }, [enqueueSnackbar, error, prevError]);

    return (
        <>
            <Titlebar
                id="titlebar"
                title="History"
                breadcrumbs={[
                    <Link
                        key="admin"
                        id="admin"
                        //@ts-ignore
                        to={`/${orgService.apiService._orgId}/admin`}
                        component={RouterLink}
                    >
                        Administration
                    </Link>
                ]}
            />

            {buildBody()}
        </>
    );
};

export default HistoryLog;
