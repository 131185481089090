import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { AdvancedTableWrapper } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapper";
import Button from "@civicplus/preamble-ui/lib/Button";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import Toggle from "@civicplus/preamble-ui/lib/Toggle";
import Titlebar from "@civicplus/preamble-ui/lib/Titlebar";
import Tooltip from "@civicplus/preamble-ui/lib/Tooltip";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { Subscriptions, SubscriptionType, User } from "../../../entities/Subscriber";
import { SubscriptionList } from "../../../entities/SubscriptionList";
import { OrganizationSettings } from "../../../entities/OrganizationSettings";
import { bottle } from "../../../provider/Bottle";
import { ManageSubscriptionsService } from "../../../services/ManageSubscriptionsService";
import { OrganizationSettingsService } from "../../../services/OrganizationSettingsService";
import { PrivacyService } from "../../../services/PrivacyService";
import { SubscribersService } from "../../../services/SubscribersService";
import { SubscriptionListsService } from "../../../services/SubscriptionListsService";
import { UserManager } from "../../../services/UserManager";
import GetPhoneNumber from "./children/GetPhoneNumber";
import { TableData, TableState } from "@civicplus/preamble-ui/lib/AdvancedTable/AdvancedTableWrapperConstants";
import { makeCustomSelectPicker } from "@civicplus/preamble-ui/lib/Utilities/CommonTableComponents";
import { buildCustomSelectPickerFilter } from "@civicplus/preamble-ui/lib/Utilities/odataHelper";
import { Paged } from "../../../entities/Paged";
import { ProductTypeDto, ProductTypeSelector } from "../../admin/shared/ProductTypeSelector";
import { ProductsWithNotifications, ProductTypeLabels } from "../../../entities/ProductType";
import { usePreviousErrors } from "../../../util/PreviousErrors";
import { useSnackbar } from "notistack";

const ManageSubscriptions: React.FC = () => {
    const service: ManageSubscriptionsService = bottle.container.ManageSubscriptionsService;
    const listsService: SubscriptionListsService = bottle.container.SubscriptionListsService;
    const userManager: UserManager = bottle.container.UserManager;
    const subscriberService: SubscribersService = bottle.container.SubscribersService;
    const privacyService: PrivacyService = bottle.container.PrivacyService;
    const organizationSettingsService: OrganizationSettingsService = bottle.container.OrganizationSettingsService;
    const orgService = bottle.container.OrgService;

    const PhoneNumberRequiredError = "You must set a phone number on the CivicPlus platform to subscribe by SMS.";
    const refContainer: any = useRef<typeof AdvancedTableWrapper | null>(null);

    const [subscriptions, setSubscriptions] = useState<Subscriptions>({});
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [showUnsubscribeModal, setShowUnsubscribeModal] = useState<boolean>(false);
    const [showPhoneDialog, setShowPhoneDialog] = useState<boolean>(false);
    const [showSignInModal, setShowSignInModal] = useState<boolean>(false);
    const [allLists, setAllLists] = useState<Paged<SubscriptionList>>({ count: 0, items: [] });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [orgSettings, setOrgSettings] = useState<OrganizationSettings | undefined>(undefined);
    const [resendSmsSubscription, setResendSmsSubscription] = useState<any | undefined>(undefined);
    const [userInfo, setUserInfo] = useState<User | any>(undefined);
    const prevSubscriptions = usePreviousErrors(subscriptions);
    const [showInternalUnsubDialog, setShowInternalUnsubDialog] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<any>(undefined);
    const { enqueueSnackbar } = useSnackbar();

    const buttons: any[] = [];

    const loadConfigs = useCallback(async () => {
        const settings = await organizationSettingsService.getOrganizationSettings();

        setOrgSettings(settings);
        setIsLoading(false);
    }, [organizationSettingsService]);

    useEffect(() => {
        if (orgService && orgService.user) {
            const loggedIn = orgService.user !== undefined && orgService.user.expired === false;
            setLoggedIn(loggedIn);
        }

        loadConfigs();
    }, [loadConfigs, orgService, orgService.expired, orgService.user]);

    const loadUserInfo = useCallback(
        async (loggedInBackup?: boolean) => {
            if (loggedIn && !userInfo) {
                const userInfo = await privacyService.getUserInfo(orgService.user.profile?.name);

                setUserInfo(userInfo ? userInfo : {});
                setPhoneNumber(userInfo.phoneNumber ?? null);
                return userInfo?.phoneNumber;
            } else if (loggedIn || loggedInBackup) {
                setPhoneNumber(userInfo.phoneNumber);
                return userInfo.phoneNumber;
            }
        },
        [loggedIn, orgService.user?.profile?.name, privacyService, userInfo]
    );

    const loadSubscriptions = useCallback(
        (lists: SubscriptionList[]) => {
            if (loggedIn) {
                const currentSubs = subscriptions;

                lists &&
                    lists.map((l: SubscriptionList) => {
                        const sss =
                            l.smsSubscription && l.emailSubscription
                                ? SubscriptionType.EmailAndSms
                                : l.emailSubscription
                                  ? SubscriptionType.Email
                                  : l.smsSubscription
                                    ? SubscriptionType.Sms
                                    : SubscriptionType.None;
                        return (currentSubs[l.id!] = sss);
                    });
                return currentSubs;
            }
            return {};
        },
        [loggedIn, subscriptions]
    );

    const isSubscribed = useCallback(
        (type: SubscriptionType, list: SubscriptionList) => {
            const checked = service.isSubscribed(subscriptions, type, list);
            return checked;
        },
        [service, subscriptions]
    );

    const unsubscribeAll = () => {
        setShowUnsubscribeModal(true);
    };

    const signIn = useCallback(async () => {
        await userManager.signIn(window.location.pathname);
        setShowSignInModal(false);
    }, [userManager]);

    const handleUnsubscribeAllPerList = async (list: SubscriptionList, type: SubscriptionType): Promise<boolean> => {
        const result = await makeSubscriptionChangeRequest(list, type, true);
        if (!result.success) {
            return result.success;
        }

        updatePrevSubscription(list, type);
        return result.success;
    };

    const calculateNewSubscriptionType = useCallback(
        (list: SubscriptionList, current: SubscriptionType): SubscriptionType => {
            return service.calculateSubscriptionType(subscriptions, current, list);
        },
        [service, subscriptions]
    );

    const makeSubscriptionChangeRequest = useCallback(
        async (
            list: SubscriptionList,
            newType: SubscriptionType,
            isCurrentlySubscribed: boolean,
            index?: number
        ): Promise<{ success: boolean; skip: boolean }> => {
            const request = { listId: list.id!, type: newType, phoneNumber: phoneNumber, listName: list.name! };

            let localPhoneNumber;
            if (!phoneNumber) {
                //check and assign phone number again

                const loggedIn = orgService.user !== undefined && orgService.user.expired === false;
                localPhoneNumber = await loadUserInfo(loggedIn);
            }

            try {
                const options = { hideAlertOnError: true };

                if (isCurrentlySubscribed) {
                    await subscriberService.unsubscribe(request);
                    const currentSubs = subscriptions;
                    currentSubs[list.id!] = newType;

                    setSubscriptions(currentSubs);
                } else {
                    if (
                        (newType === SubscriptionType.Sms || newType === SubscriptionType.EmailAndSms) &&
                        !phoneNumber &&
                        !localPhoneNumber
                    ) {
                        throw new Error(PhoneNumberRequiredError);
                    }
                    const result = await subscriberService.subscribe(request, options);

                    return { success: result.success, skip: false };
                }
            } catch (e: any) {
                if (
                    (e.error && e.error.toString().toLowerCase() === PhoneNumberRequiredError.toLowerCase()) ||
                    (e.message && e.message.toString() === PhoneNumberRequiredError)
                ) {
                    setShowPhoneDialog(true);

                    setResendSmsSubscription({
                        list,
                        newType,
                        isCurrentlySubscribed,
                        index
                    });

                    return { skip: true, success: false };
                }
                return { skip: false, success: false };
            }

            return { skip: false, success: true };
        },
        [loadUserInfo, orgService.user, phoneNumber, subscriberService, subscriptions]
    );

    const getRows = async (tableState: TableState): Promise<TableData> => {
        const filter = buildFilter(tableState);

        const lists: Paged<SubscriptionList> = await listsService.getSubscriptionLists(
            tableState.page,
            tableState.rowsPerPage,
            false,
            tableState.sortOrder.name || "name",
            tableState.sortOrder.direction || "asc",
            filter
        );

        const newAllLists = lists.items.concat(allLists.items).reduce((prev: SubscriptionList[], current) => {
            if (!prev.some((x) => x.id === current.id)) {
                prev.push(current);
            }

            return prev;
        }, []);

        const subscriptions: Subscriptions = loadSubscriptions(lists.items);

        setSubscriptions(subscriptions);
        setAllLists({ count: newAllLists.length, items: newAllLists });

        const data = lists!.items?.map((list, i) => {
            return buildRow(list, i);
        });

        return { count: lists.count, data };
    };

    const buildProductList = (list: SubscriptionList) => {
        if (list.productTypeList) {
            return (
                <Typography variant="body1">
                    {list.productTypeList
                        .sort((a, b) => ProductTypeLabels[a].localeCompare(ProductTypeLabels[b]))
                        .map((x) => ProductTypeLabels[x])
                        .join(", ")}
                </Typography>
            );
        }
    };

    const toggleSubscription = useCallback(
        (subscriptionType: number, list: SubscriptionList) => {
            const sus = subscriptions[list.id!];

            if (sus === subscriptionType || sus === 2) {
                return true;
            }
            return false;
        },
        [subscriptions]
    );

    const onSwitchChange = async (
        type: SubscriptionType,
        list: SubscriptionList,
        index: number,
        isUnsubscribeAll = false
    ) => {
        if (!loggedIn) {
            setShowSignInModal(true);

            return;
        }

        if (isUnsubscribeAll) {
            const unsubAll = await handleUnsubscribeAllPerList(list, type);

            const allListsLocal = allLists;
            allListsLocal.items.forEach(async (e) => {
                e.emailSubscription = false;
                e.smsSubscription = false;
                toggleSubscription(SubscriptionType.None, e);
            });

            const newRow = buildRow(list, index);

            const tableRef = getTableRef();
            tableRef?.reloadRow(index, newRow);

            setAllLists(allListsLocal);

            return unsubAll;
        }

        if (
            list.internalOnly &&
            !orgService?.organization?.isOrgOwner &&
            calculateNewSubscriptionType(list, type) === SubscriptionType.None
        ) {
            setShowInternalUnsubDialog(true);
            setItemToRemove({ index: index, item: list, subType: type });
            return;
        }
        await updateToggle(type, list, index);
    };

    const buildRow = useCallback(
        (list: SubscriptionList, index: number) => {
            const rows = [
                list.id,
                // Column = Name
                <p key={`${list.id}-name`} style={{ wordBreak: "break-all" }}>
                    {list.name}
                    <br></br> {list.description}
                </p>,
                buildProductList(list),
                // Column = SubscribeToEmail
                <Grid key={`${list.id}-email`} container={true} alignItems="center">
                    <Tooltip delay="instant" title="Receive Email Notifications" placement="bottom">
                        <MailOutlineIcon />
                    </Tooltip>

                    <Toggle
                        id={`toggle-email-${list.id}`}
                        value={toggleSubscription(SubscriptionType.Email, list)}
                        checked={toggleSubscription(SubscriptionType.Email, list)}
                        inputProps={
                            toggleSubscription(SubscriptionType.Email, list)
                                ? { title: `Unsubscribe to ${list.name} via Email` }
                                : { title: `Subscribe to ${list.name} via Email` }
                        }
                        onChange={() => onSwitchChange(SubscriptionType.Email, list, index)}
                    />
                </Grid>,

                // Column = EmailSubscription
                toggleSubscription(SubscriptionType.Email, list).toString(),

                // Column = SubscribeToSMS
                <Grid key={`${list.id}-sms`} container={true} alignItems="center">
                    <Tooltip delay="instant" title="Receive SMS Notifications" placement="bottom">
                        <SmartphoneIcon />
                    </Tooltip>

                    <Toggle
                        id={`toggle-sms-${list.id}`}
                        value={toggleSubscription(SubscriptionType.Sms, list)}
                        checked={toggleSubscription(SubscriptionType.Sms, list)}
                        onChange={() => onSwitchChange(SubscriptionType.Sms, list, index)}
                        inputProps={
                            toggleSubscription(SubscriptionType.Sms, list)
                                ? { title: `Unsubscribe to ${list.name} via SMS` }
                                : { title: `Subscribe to ${list.name} via SMS` }
                        }
                        disabled={!list.isListSMSEnabled}
                    />
                </Grid>,

                // Column = SMSSubscription
                toggleSubscription(SubscriptionType.Sms, list).toString()
            ];

            return rows;
        },
        [onSwitchChange, toggleSubscription]
    );

    const updatePrevSubscription = useCallback(
        (list: SubscriptionList, type: SubscriptionType) => {
            if (list && list.id) {
                if (prevSubscriptions) {
                    if (!((type === SubscriptionType.Sms || type === SubscriptionType.EmailAndSms) && !phoneNumber)) {
                        (prevSubscriptions as any)[list.id] = type;
                        setSubscriptions(prevSubscriptions);
                    }
                }
            }
        },
        [phoneNumber, prevSubscriptions]
    );

    const getTableRef = useCallback(() => {
        return refContainer && (refContainer.current as typeof AdvancedTableWrapper);
    }, []);

    const updateToggle = useCallback(
        async (
            type: SubscriptionType,
            list: SubscriptionList,
            index: number
        ): Promise<{ success: boolean; skip: boolean }> => {
            const originalState = JSON.parse(JSON.stringify(subscriptions));

            const isSubbed = isSubscribed(type, list);

            const newSubscriptionType = calculateNewSubscriptionType(list, type);

            const result = await makeSubscriptionChangeRequest(list, newSubscriptionType, isSubbed, index);

            updatePrevSubscription(list, type);

            const currentSub = subscriptions;
            if (list.id && !result.skip) {
                currentSub[list.id] = newSubscriptionType;
                setSubscriptions(currentSub);
            }

            //Edge case if they click cancel on adding phone number, skip and do not set the subscription.
            if (result.skip) {
                setSubscriptions(originalState);
            } else if (!result.success) {
                enqueueSnackbar(`Failed to update subscription for "${list.name}". Please try again.`, {
                    variant: "error"
                });
                setSubscriptions(originalState);
            } else {
                const tableRef = getTableRef();
                if (tableRef) {
                    tableRef?.reloadRow(index, buildRow(list, index));
                }

                enqueueSnackbar(service.convertTypeToMessage(newSubscriptionType, list), { variant: "success" });
            }

            return result;
        },
        [
            buildRow,
            calculateNewSubscriptionType,
            getTableRef,
            isSubscribed,
            makeSubscriptionChangeRequest,
            service,
            subscriptions,
            updatePrevSubscription,
            enqueueSnackbar
        ]
    );

    const retrySmsSubscription = useCallback(async () => {
        const { list, newType, isCurrentlySubscribed, index } = resendSmsSubscription;

        const result = await makeSubscriptionChangeRequest(list, newType, isCurrentlySubscribed);

        if (result.success) {
            updatePrevSubscription(list, newType);
            setResendSmsSubscription(undefined);

            const tableRef = getTableRef();
            if (tableRef) {
                tableRef?.reloadRow(index, buildRow(list, index));
            }

            enqueueSnackbar(service.convertTypeToMessage(newType, list), { variant: "success" });
        } else {
            setResendSmsSubscription(undefined);
            enqueueSnackbar(`Failed to update subscription for "${list.name}". Please try again.`, {
                variant: "error"
            });
        }
    }, [
        buildRow,
        getTableRef,
        makeSubscriptionChangeRequest,
        resendSmsSubscription,
        service,
        updatePrevSubscription,
        enqueueSnackbar
    ]);

    const buildBody = () => {
        const orgApps = orgService.organization?.applications?.map((x: any) => x.productType);
        const includedApps = orgApps ? ProductsWithNotifications.filter((p) => orgApps?.includes(p)) : [];

        const columns = [
            {
                name: "id",
                options: {
                    sort: false,
                    filter: false,
                    display: "false"
                }
            },
            { name: "name", label: "Name", options: { sort: true, searchabe: true, filter: false } },
            {
                name: "Product",
                options: {
                    customFilterListOptions: { render: (v: any) => v.map((x: any) => x.label) },
                    filter: true,
                    filterType: "custom",
                    filterOptions: {
                        display: function productCustomFilter(
                            filterList: any,
                            onChange: any,
                            index: number,
                            column: any
                        ): ReactElement {
                            return (
                                <ProductTypeSelector
                                    includedApps={includedApps}
                                    isInDialog={true}
                                    label="Product"
                                    onChange={function (productType: ProductTypeDto[]): void {
                                        onChange(
                                            productType.map((x) => {
                                                return { label: x.friendlyName, name: x.name, value: x.value };
                                            }),
                                            index,
                                            column
                                        );
                                    }}
                                    placeholder="Select or type filter..."
                                    value={filterList[index].map((x: any) => x.value)}
                                />
                            );
                        }
                    },
                    sort: false
                }
            },
            {
                name: "SubscribeToEmail",
                label: "Subscribe to email",
                options: {
                    filter: false,
                    sort: false
                }
            },
            {
                name: "EmailSubscription",
                label: "Email Subscriptions",
                options: {
                    sort: false,
                    ...makeCustomSelectPicker("EmailSubscription", [
                        { label: "Subscribed", value: true },
                        { label: "Not Subscribed", value: false }
                    ]),
                    filter: true,
                    display: "false"
                }
            }
        ];

        if (orgSettings?.isSMSEnabled) {
            const smsColumns = [
                {
                    name: "SubscribeToSMS",
                    label: "Subscribe to SMS",
                    options: {
                        filter: false,
                        sort: false
                    }
                },
                {
                    name: "SmsSubscription",
                    label: "Sms Subscriptions",
                    options: {
                        sort: false,
                        ...makeCustomSelectPicker("SmsSubscription", [
                            { label: "Subscribed", value: true },
                            { label: "Not Subscribed", value: false }
                        ]),
                        filter: true,
                        display: "false"
                    }
                }
            ];

            columns.push(...smsColumns);
        }

        return (
            <AdvancedTableWrapper
                columns={columns}
                rows={getRows}
                scrollToTop={true}
                ref={refContainer}
                emptyMessage="There are currently no subscription lists. Set up your first list to get started!"
                initialSortDirection="asc"
                initialSortColumn="name"
                initialSortColumnIndex={1}
                showFilter={loggedIn}
                viewColumns={false}
                showSearch={true}
                download={false}
                rowsPerPage={25}
                rowsPerPageOptions={[25, 50, 100]}
                closeSnackbar={() => {}}
                enqueueSnackbar={() => "message"}
                resetPageOnFilterChange={true}
            />
        );
    };

    const buildFilter = (state: TableState) => {
        const productFilter = buildCustomSelectPickerFilter(
            state.filterList[2]?.map((x: any) => {
                return x.name;
            }) ?? [],
            "subscriptionListProductType",
            true,
            "productType"
        );

        const emailFilter = buildCustomSelectPickerFilter(state.filterList[4], "EmailSubscription", false);
        const smsFilter = buildCustomSelectPickerFilter(state.filterList[6], "SmsSubscription", false);

        const filter = {
            ...productFilter,
            ...smsFilter,
            ...emailFilter
        };
        const smsFilterApplied = filter.SmsSubscription && (filter.SmsSubscription as any)["in"]![0]!;
        const emailFilterApplied = filter.EmailSubscription && (filter.EmailSubscription as any)["in"]![0]!;
        delete filter.SmsSubscription;
        delete filter.EmailSubscription;

        let subsFilter = {};
        if (smsFilterApplied && emailFilterApplied) {
            subsFilter = {
                any: {
                    type: "EmailAndSms"
                }
            };
            filter.subscriptions = subsFilter;
        } else if (smsFilterApplied === false && emailFilterApplied === false) {
            subsFilter = {
                any: {
                    type: "None"
                }
            };
            filter.subscriptions = subsFilter;
        } else if (smsFilterApplied && emailFilterApplied === false) {
            subsFilter = {
                any: {
                    and: [
                        { type: "Sms" },
                        {
                            not: [{ type: "EmailAndSms" }, { type: "Email" }]
                        }
                    ]
                }
            };
            filter.subscriptions = subsFilter;
        } else if (smsFilterApplied === false && emailFilterApplied) {
            subsFilter = {
                any: {
                    and: [
                        { type: "Email" },
                        {
                            not: [{ type: "EmailAndSms" }, { type: "Sms" }]
                        }
                    ]
                }
            };
            filter.subscriptions = subsFilter;
        } else if (smsFilterApplied) {
            subsFilter = {
                any: {
                    or: [{ type: "Sms" }, { type: "EmailAndSms" }]
                }
            };
            filter.subscriptions = subsFilter;
        } else if (emailFilterApplied) {
            subsFilter = {
                any: {
                    or: [{ type: "Email" }, { type: "EmailAndSms" }]
                }
            };
            filter.subscriptions = subsFilter;
        } else if (smsFilterApplied === false) {
            subsFilter = {
                subscriptions: {
                    any: {
                        or: [{ type: "Sms" }, { type: "EmailAndSms" }]
                    }
                }
            };
            filter.not = subsFilter;
        } else if (emailFilterApplied === false) {
            subsFilter = {
                subscriptions: {
                    any: {
                        or: [{ type: "Email" }, { type: "EmailAndSms" }]
                    }
                }
            };
            filter.not = subsFilter;
        }

        if (state.search) {
            filter["or"] = {
                // @ts-ignore
                name: {
                    contains: state.search.trim()
                },
                description: {
                    contains: state.search.trim()
                }
            };
        }

        return filter;
    };

    useEffect(() => {
        if (loggedIn) {
            loadUserInfo();
        }

        if (phoneNumber && resendSmsSubscription) {
            retrySmsSubscription();
        }
    }, [buttons, loadUserInfo, loggedIn, phoneNumber, resendSmsSubscription, retrySmsSubscription, signIn]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Titlebar
                        title="My Subscriptions"
                        buttons={[
                            !loggedIn ? (
                                <Button key="signin" id="newbutton" color="primary" onClick={signIn}>
                                    Sign in to Manage Subscriptions
                                </Button>
                            ) : (
                                <Button
                                    key="unsubscribe-all"
                                    onClick={unsubscribeAll}
                                    color="primary"
                                    id="unsubscribeAllButton"
                                >
                                    Unsubscribe From All
                                </Button>
                            )
                        ]}
                    />

                    <Grid container={true} spacing={3}>
                        <Grid sm={12}>
                            <>{buildBody()}</>
                        </Grid>
                    </Grid>

                    {showUnsubscribeModal && (
                        <Dialog
                            title="Unsubscribe"
                            open={showUnsubscribeModal}
                            onClose={() => {
                                setShowUnsubscribeModal(false);
                            }}
                            actions={[
                                <Button
                                    color="primary"
                                    id="ok-unsubscribe-all"
                                    key="ok"
                                    onClick={async () => {
                                        const newEndpoint = await Promise.resolve(await listsService.getAllLists());
                                        const subscribed = newEndpoint.filter(
                                            (l) =>
                                                isSubscribed(SubscriptionType.EmailAndSms, l) ||
                                                isSubscribed(SubscriptionType.Email, l) ||
                                                isSubscribed(SubscriptionType.Sms, l)
                                        );
                                        let rowCount = 0;
                                        const results = await Promise.all(
                                            subscribed?.map((s) =>
                                                onSwitchChange(SubscriptionType.None, s, rowCount++, true)
                                            )
                                        );
                                        const failures = results.filter((r: any) => !r);

                                        setShowUnsubscribeModal(false);

                                        const tableRef = getTableRef();
                                        tableRef?.loadRows();

                                        enqueueSnackbar(
                                            failures.length > 0
                                                ? `Failed to unsubscribe from ${failures.length} lists, please try again.`
                                                : "You are now unsubscribed from all lists.",
                                            { variant: "success" }
                                        );
                                    }}
                                >
                                    Ok
                                </Button>,
                                <Button
                                    key="cancel"
                                    id="cancel-unsubscribe-all"
                                    onClick={() => {
                                        setShowUnsubscribeModal(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            ]}
                        >
                            <Typography>Are you sure you want to unsubscribe from all lists?</Typography>
                        </Dialog>
                    )}

                    {showPhoneDialog && (
                        <Dialog
                            open={showPhoneDialog}
                            title="Phone Number"
                            onClose={() => {
                                setShowPhoneDialog(false);
                            }}
                        >
                            <GetPhoneNumber
                                callback={(phone: string | null) => {
                                    return new Promise((resolve) => {
                                        setPhoneNumber(phone);
                                        setShowPhoneDialog(false);
                                        //@ts-ignore
                                        resolve();
                                    });
                                }}
                                onCancel={() => {
                                    return new Promise((resolve) => {
                                        setShowPhoneDialog(false);
                                        //@ts-ignore
                                        resolve();
                                    });
                                }}
                            />
                        </Dialog>
                    )}

                    {showSignInModal && (
                        <Dialog
                            title="Sign In"
                            open={showSignInModal}
                            onClose={() => {
                                setShowSignInModal(false);
                            }}
                            actions={[
                                <Button color="primary" onClick={() => signIn()} key="ok">
                                    Sign In
                                </Button>,
                                <Button
                                    key="cancel"
                                    onClick={() => {
                                        setShowSignInModal(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            ]}
                        >
                            <Typography>You must sign in to subscribe to notifications.</Typography>
                        </Dialog>
                    )}

                    <Dialog
                        title="Unsubscribe"
                        open={showInternalUnsubDialog}
                        onClose={() => {
                            setShowInternalUnsubDialog(false);
                        }}
                        actions={[
                            <Button
                                color="primary"
                                data-testid="internalUnsubscribeButtonTest"
                                id="internalUnsubscribeButton"
                                key="internalOk"
                                onClick={async () => {
                                    if (!itemToRemove) {
                                        setShowInternalUnsubDialog(false);
                                        return;
                                    } else {
                                        const index = itemToRemove.index;
                                        const item = itemToRemove.item;
                                        const subType = itemToRemove.subType;
                                        const result = await updateToggle(subType, item, index);

                                        if (result.success) {
                                            const tableRef = getTableRef();
                                            tableRef?.loadRows();

                                            setShowInternalUnsubDialog(false);
                                            setItemToRemove(null);
                                        } else {
                                            setShowInternalUnsubDialog(false);
                                        }
                                    }
                                }}
                            >
                                Ok
                            </Button>,
                            <Button
                                key="cancel"
                                onClick={() => {
                                    setShowInternalUnsubDialog(false);
                                }}
                            >
                                Cancel
                            </Button>
                        ]}
                    >
                        <Typography>Are you sure you want to unsubscribe from this internal-only list?</Typography>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default ManageSubscriptions;
