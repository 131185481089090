import React from "react";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import MetricCard from "@civicplus/preamble-ui/lib/MetricCard";
import SvgIcon from "@mui/material/SvgIcon";
import { mdiEmailArrowRightOutline, mdiMessageProcessingOutline } from "@mdi/js";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip as ChartTip,
    XAxis,
    YAxis
} from "recharts";

interface MessageHistoryStatisticsTabProps {
    emailCount: number;
    smsCount: number;
    stats: any[];
}

const MessageHistoryStatisticsTab: React.FC<MessageHistoryStatisticsTabProps> = ({ emailCount, smsCount, stats }) => {
    return (
        <Grid xs={12}>
            <Grid container={true} spacing={3}>
                <Grid xs={6}>
                    <div style={{ marginRight: "3%", marginBottom: "6%" }}>
                        <MetricCard
                            id="metric-emails"
                            title="Emails Sent"
                            content={emailCount}
                            measure="emails"
                            label=""
                            icon={
                                <SvgIcon color="inherit">
                                    <path d={mdiEmailArrowRightOutline} />
                                </SvgIcon>
                            }
                        />
                    </div>
                </Grid>

                <Grid xs={6}>
                    <div style={{ marginLeft: "3%", marginBottom: "6%" }}>
                        <MetricCard
                            id="metric-sms"
                            title="SMS Sent"
                            content={smsCount}
                            measure="messages"
                            icon={
                                <SvgIcon color="inherit">
                                    <path d={mdiMessageProcessingOutline} />
                                </SvgIcon>
                            }
                        />
                    </div>
                </Grid>
            </Grid>

            <Grid container={true} spacing={3}>
                <Grid xs={12}>
                    <div style={{ marginRight: "0", marginBottom: "6%" }}>
                        <MetricCard
                            id="stats-chart"
                            title="Email Statistics"
                            label={
                                <ResponsiveContainer minHeight={544}>
                                    <AreaChart
                                        width={500}
                                        height={400}
                                        data={stats}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis label={{ value: "Total", angle: -90, position: "insideLeft" }} />
                                        <ChartTip />
                                        <Legend verticalAlign="top" height={36} />
                                        <Area
                                            type="monotone"
                                            dataKey="opened"
                                            name="opened"
                                            stackId="1"
                                            stroke="#1779B0"
                                            fill="#1779B0"
                                            activeDot={{ r: 8 }}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="bounced"
                                            name="bounced"
                                            stackId="1"
                                            stroke="#F1ADA1"
                                            fill="#F1ADA1"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MessageHistoryStatisticsTab;
