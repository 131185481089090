import { errorMessage } from "@civicplus/preamble-ui/lib/Validations";

interface softMaxLengthValidationProps {
    errorMessages: { [key: string]: string };
    softMaxLength: number;
    label?: string;
}

export const softMaxLengthValidation = (value: string, props: softMaxLengthValidationProps) => {
    const validationName = "SoftMaxLength";
    const defaultErrorMessage = `${props.label ?? "Input"} length must be under ${props.softMaxLength} characters`;

    const isValid = value ? value.length < props.softMaxLength : true;

    const result = {
        error: !isValid,
        message: errorMessage(validationName, props.errorMessages, defaultErrorMessage),
        validationName: validationName
    };

    return result.error ? Promise.reject(result) : Promise.resolve(result);
};
