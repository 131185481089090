import React, { useEffect, useState } from "react";
import DateTimeRange from "@civicplus/preamble-ui/lib/DateTimeRange";
import enhanceWithValidation, {
    rangeRequiredValidation,
    dateRangeValidDateValidation,
    dateRangeBoundaryValidation,
    dateRangeMinValueValidation,
    dateRangeMaxValueValidation
} from "@civicplus/preamble-ui/lib/Validations";

export interface IEnhancedDateRange {
    currentDate: Date;
    defaultStartRangeInMonths: number;
    maxRangeInYears: number;
    maxNumberOfYearsToSearch: number;
    onDateRangeChange: (startDate: Date, endDate: Date) => void;
}

export const EnhancedDateRange: React.FC<IEnhancedDateRange> = (props) => {
    const EnhancedDateRangeWithValidation = enhanceWithValidation(DateTimeRange);

    const defaultDateRangeStart = new Date(props.currentDate);
    defaultDateRangeStart.setMonth(defaultDateRangeStart.getMonth() - props.defaultStartRangeInMonths);
    defaultDateRangeStart.setHours(0, 0, 0);

    const minDateRangeStart = new Date(props.currentDate);
    minDateRangeStart.setFullYear(minDateRangeStart.getFullYear() - props.maxNumberOfYearsToSearch);
    minDateRangeStart.setDate(minDateRangeStart.getDate() + 1); // Ensure date stays within maxNumberOfYearsToSearch range
    minDateRangeStart.setHours(0, 0, 0); // Prevents min value validation from trigerring as the date comparison also uses time

    const [dateRangeStart, setDateRangeStart] = useState<Date>(new Date(defaultDateRangeStart));
    const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date(props.currentDate));

    useEffect(() => {
        // this value can be set to null by the component when validation triggers
        if (dateRangeStart !== null) {
            let maxValue = new Date(dateRangeStart);
            maxValue.setFullYear(dateRangeStart.getFullYear() + props.maxRangeInYears);
            maxValue.setDate(maxValue.getDate() - 1); // Ensure date stays within max range
            if (maxValue > props.currentDate) {
                maxValue = props.currentDate;
            }

            if (maxValue < dateRangeEnd) {
                setDateRangeEnd(maxValue);
            }
        }
    }, [dateRangeStart]);

    const onDateRangeChange = (newValues: any) => {
        setDateRangeStart(newValues.from);
        setDateRangeEnd(newValues.to);
        props.onDateRangeChange(newValues.from, newValues.to);
    };

    return (
        <EnhancedDateRangeWithValidation
            id="Date-Range"
            pickerType="date"
            autofocus={false}
            onChange={onDateRangeChange}
            required={true}
            r={true}
            fromProps={{ label: "From" }}
            toProps={{ label: "To" }}
            value={{ from: dateRangeStart, to: dateRangeEnd }}
            minValue={minDateRangeStart}
            maxValue={props.currentDate}
            fullWidth={true}
            validations={[
                rangeRequiredValidation,
                dateRangeMinValueValidation,
                dateRangeMaxValueValidation,
                dateRangeValidDateValidation,
                dateRangeBoundaryValidation
            ]}
        />
    );
};
