import { EmailEvent, MessageHistory } from "../entities/Message";
import { ODataQuery } from "../entities/ODataQuery";
import { Paged } from "../entities/Paged";
import { ApiService } from "./ApiService";

export class MessageService {
    private readonly apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    public async getMessageHistory(
        page = 0,
        rowsPerPage = 25,
        listId: number | null,
        sortColumn = "id",
        sortDirection = "desc",
        filter?: any
    ): Promise<Paged<MessageHistory>> {
        const odata = new ODataQuery({
            top: rowsPerPage,
            skip: page * rowsPerPage,
            orderBy: sortColumn,
            orderByDirection: sortDirection,
            filter
        });

        return await this.apiService.getOdata(`messages/history/${listId ?? ""}`, odata);
    }

    public async getMessageDetails(messageId: number, listId: number | null): Promise<MessageHistory> {
        let url = `messages/history/details/${messageId}`;

        if (listId !== null) {
            url += `?listId=${listId}`;
        }

        return await this.apiService.get(url);
    }

    public async getSentMessages(type: number, startDateUtc: Date, endDateUtc: Date): Promise<Paged<MessageHistory>> {
        return await this.apiService.get(
            `messages/stats/sent?type=${type}&startDateUtc=${startDateUtc.toJSON()}&endDateUtc=${endDateUtc.toJSON()}`
        );
    }

    public async getMessageEventsForEmail(email: string, top: number, skip: number): Promise<Paged<EmailEvent>> {
        return await this.apiService.getNoOrg(
            `messages/events/email?$top=${top}&$skip=${skip}&$filter=to eq '${encodeURIComponent(email)}'&$orderby=time desc&$count=true`
        );
    }

    public async getSentMessageCounts(messageId: number, listId?: number): Promise<{ sms: number; email: number }> {
        let url = `messages/stats/${messageId}/count`;

        if (listId) {
            url = `${url}?listId=${listId}`;
        }

        return await this.apiService.get(url);
    }

    public async getMessageActivityDetails(messageId: number, externalId: string): Promise<any[]> {
        return await this.apiService.get(`messages/stats/${messageId}/activity/${externalId}/email?$top=100`);
    }

    public async getMessageActivityDetailsByTag(tag: string): Promise<any[]> {
        return await this.apiService.get(`messages/stats/activity/tag/${tag}`);
    }

    public async getSmsMessageActivityDetails(messageId: number, externalId: string): Promise<any[]> {
        return await this.apiService.get(`messages/stats/${messageId}/activity/${externalId}/sms?$top=100`);
    }

    public async getSentMessageChartStats(messageId: number, listId?: number): Promise<any[]> {
        let url = `messages/stats/${messageId}/chart`;
        if (listId) {
            url = `${url}?listId=${listId}`;
        }

        const resp = await this.apiService.get(url);

        if (!resp) {
            return [];
        }

        const formatted = await Promise.all(
            resp.map((x: any) => {
                return {
                    date: new Date(`${x.month}/${x.day}/${x.year}`).toLocaleDateString(),
                    type: x.type,
                    count: x.count
                };
            })
        );

        return await this.groupByDate(formatted);
    }

    public async getSentMessageActivity(
        messageId: number,
        listId?: number | null,
        page = 0,
        rowsPerPage = 25,
        sortColumn = "time",
        sortDirection = "desc",
        filter?: any
    ): Promise<Paged<any>> {
        const odata = new ODataQuery({
            top: rowsPerPage,
            skip: page * rowsPerPage,
            orderBy: sortColumn,
            orderByDirection: sortDirection,
            filter
        });

        let url = `messages/stats/${messageId}/activity/email`;

        if (listId) {
            url = `${url}?listId=${listId}`;
        }

        return await this.apiService.getOdata(url, odata);
    }

    public async getSentMessageActivitySms(
        messageId: number,
        listId: number | null,
        page = 0,
        rowsPerPage = 25,
        sortColumn = "time",
        sortDirection = "desc",
        filter?: any
    ): Promise<Paged<any>> {
        const odata = new ODataQuery({
            top: rowsPerPage,
            skip: page * rowsPerPage,
            orderBy: sortColumn,
            orderByDirection: sortDirection,
            filter
        });

        let url = `messages/stats/${messageId}/activity/sms`;

        if (listId) {
            url = `${url}?listId=${listId}`;
        }

        return await this.apiService.getOdata(url, odata);
    }

    public async getSentMessagesHistory(startDateUtc: Date, endDateUtc: Date): Promise<any> {
        const resp = await this.apiService.get(
            `messages/stats/history?startDateUtc=${startDateUtc.toJSON()}&endDateUtc=${endDateUtc.toJSON()}`
        );

        const formatted = await Promise.all(
            resp.map((x: any) => {
                return {
                    date: new Date(`${x.month}/${x.day}/${x.year}`).toLocaleDateString(),
                    type: x.type,
                    sent: x.sent
                };
            })
        );

        return await this.groupByDate(formatted);
    }

    private async groupByDate(arrayObjects: any[]) {
        return await arrayObjects.reduce(async (promise, currentObject) => {
            const result = await promise;

            if (result) {
                const val = currentObject["date"];
                result[val] = result[val] || [];
                result[val].push(currentObject);
            }

            return result;
        }, Promise.resolve([]));
    }
}
