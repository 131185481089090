import React, { useState } from "react";
import Dialog from "@civicplus/preamble-ui/lib/Dialog";
import { bottle } from "../../provider/Bottle";
import { HcmsService } from "../../services/HcmsService";
import { HcmsStatus } from "../../entities/Hcms";
import { NotificationDetails, PendingMessage } from "../../entities/PendingMessage";
import { NotificationOption } from "../../entities/NotificationOption";
import { Scheduler, SchedulerProps } from "@civicplus/preamble-ui-hcms/lib/Shared/Schedule/Scheduler";

type ModalProps = {
    message: PendingMessage;
    onClose?: (reload: boolean) => any;
    contentType: any;
    open: boolean;
};

type ItemDto = {
    id: string;
    notificationDetails: NotificationDetails;
};

export const ModifyNotification: React.FC<ModalProps> = (props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(true);
    const [item, setItem] = useState<PendingMessage>(props.message);

    const hcmsService: HcmsService = bottle.container.HcmsService;

    const isAsset = (): boolean => {
        return !item.contentTypeId;
    };

    const onModalClose = (reload = false) => {
        setModalOpen(false);
        if (typeof reload === "object") {
            reload = false;
        }
        props.onClose && props.onClose(reload);
    };

    const confirm = async (notificationDetails: any) => {
        if (item && item!.notificationDetails) {
            if (
                item!.notificationDetails.notificationOption === NotificationOption.OnPublishDate &&
                item.status === HcmsStatus.Published
            ) {
                item!.notificationDetails.sendAt = null;
                item!.notificationDetails.eventType = null;
                setItem(item);
                await updateNotificationDetails(notificationDetails);
            } else {
                updateNotificationDetails(notificationDetails);
            }
        }
    };

    async function updateNotificationDetails(notificationDetails: any) {
        await hcmsService.updateNotificationDetails(item.itemId, item.contentTypeName, notificationDetails, isAsset());
        onModalClose(true);
    }

    const getSchedulerProps = () => {
        const pendingIem: ItemDto = {
            notificationDetails: item.notificationDetails,
            id: item.id
        };

        const schedulerProps: SchedulerProps = {
            id: "modify-notification",
            status: "Published",
            onConfirm: async (): Promise<void> => {
                confirm(item!.notificationDetails);
            },
            onCancel: onModalClose,
            defaultEmailSubject: item.subject,
            defaultEmailIntroduction: item!.notificationDetails.emailIntroduction!,
            defaultTextMessage: item!.notificationDetails.smsMessage,
            schema: props.contentType,
            //@ts-ignore
            item: pendingIem
        };
        return schedulerProps;
    };

    return (
        <Dialog
            id="scheduler-modal"
            open={modalOpen}
            title="Notification"
            onClose={() => {
                onModalClose();
            }}
        >
            <Scheduler {...getSchedulerProps()} />
        </Dialog>
    );
};
