import React from "react";
import Grid from "@civicplus/preamble-ui/lib/Grid";
import Link from "@civicplus/preamble-ui/lib/Link";
import Typography from "@civicplus/preamble-ui/lib/Typography";
import { Link as RouterLink } from "react-router-dom";

import { BasicList, MessageHistory } from "../../../../entities/Message";

const fromField = (messageHistory: MessageHistory, from?: string | undefined | null): JSX.Element => {
    if (!from) {
        from = "<noreply@civicplus.com>";
        // TODO: Also use Reply-To-Email instead of noreply
        if (messageHistory?.fromName) {
            from = `"${messageHistory.fromName}" <noreply@civicplus.com>`;
        }
    }

    return (
        <Grid xs={12}>
            <Grid container={true}>
                <Grid xs={12} sm={3} md={2}>
                    <Typography paragraph={true}>From:</Typography>
                </Grid>

                <Grid xs={12} sm={9} md={10}>
                    <Typography color="textSecondary">{from}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

const renderListSection = (orgId: any, lists?: BasicList[]): any => {
    if (lists && lists?.length > 0) {
        const links = lists.map((item: BasicList, index: number) => {
            const commaSeparator = index !== lists.length - 1 ? ", " : "";

            return (
                <span key={index}>
                    <Link
                        component={RouterLink}
                        target="_blank"
                        to={`/${orgId}/admin/lists/${item.id}/edit`}
                        underline="hover"
                        color="inherit"
                    >
                        {item.name}{" "}
                    </Link>
                    {commaSeparator}
                </span>
            );
        });

        return links;
    } else {
        return "This message was not sent to any notifications lists.";
    }
};

export { renderListSection, fromField };
